* {
  box-sizing: border-box;
  /*border: 1px solid red;*/
}

body, html {
  overflow-x: hidden;
  height: 100%;
  margin: 0;
  /*background-color: lightgray;*/
  background-color: whitesmoke;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  /*min-width: 60vw;*/
  /*min-width: 80%;*/
  margin: 0;
  flex-grow: 1;
  padding-bottom: 20px;
}

.main-content {
  flex-grow: 1;
  padding-bottom: 20px;
  /*min-width: 80%;*/
}


.App {
  text-align: center;
  /*min-width: 80vw;*/
  min-width: 80%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.home-screen-cards {
  /*background-color: lightgray;*/
  /*min-height: 100vh;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: space-between;*/
  /*!*font-size: calc(10px + 2vmin);*!*/
  /*color: black;*/
  margin: auto; /* Centers the flex container */
  /*background-color: lightgray;*/
  background-color: whitesmoke;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center aligns the flex items horizontally */
  justify-content: space-between;
  color: black;
  width: 80%; /* Adjust as needed */
  max-width: 800px; /* Optional max-width */
}

.Post-success-body {
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 5vh;
  margin-top: 5vh;
}

.Subscribe-preview-card {
  /*background-color: #282c34;*/
  /*background-color: gray;*/
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /*margin-top: 0.5vh;*/
  margin-bottom: 5vh;
}

.Posts-body {
  /*background-color: #282c34;*/
  /*background-color: lightgray;*/
  background-color: whitesmoke;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /*color: white;*/
  color: black;
  margin-bottom: 5vh;
}

.Post-preview-body {
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 5vh;
}

.button-bottom-space {
  margin-bottom: 20px;  /* Adjust this value as needed */
}

.App-pricing {
  /*background-color: lightgray;*/
  background-color: whitesmoke;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  /*color: white;*/
  color: black;
}

/* Method 1 - inline or inline-block elements */
.card-container {
  text-align: center;
}

.card {
  display: inline-block;
}

/* Method 2 - block-level elements */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  margin: 10px; /* optional: add some spacing between cards */
}

.App-body {
  /*background-color: lightgray;*/
  background-color: whitesmoke;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  /*color: white;*/
  color: black;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mobileFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}


@media (min-width: 701px) and (max-width: 1120px) {
  * {
    box-sizing: border-box;
    /*border: 1px solid red;*/
  }

  body, html {
    overflow-x: hidden;
  }

  .App {
    overflow-x: hidden;
    /*border: 10px solid red;*/
    /*max-width: 100%;*/
  }

  .App-body {
    overflow-x: hidden;
  }

  .home-screen-cards {
    overflow-x: hidden;
  }

  .app-container {
    min-height: 100vh;
    margin: 0;
  }
}

@media (max-width: 700px) {
  * {
    box-sizing: border-box;
    /*border: 1px solid red;*/
  }

  body, html {
    overflow-x: hidden;
  }

  .App {
    overflow-x: hidden;
    /*border: 10px solid red;*/
  }

  .App-body {
    overflow-x: hidden;
  }

  .home-screen-cards {
    overflow-x: hidden;
  }

  .app-container {
    min-height: 100vh;
    margin: 0;
  }
}
